import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import { alpha } from '@mui/system/colorManipulator';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';

import { AccountButton } from '../account-button';
import { NotificationsButton } from '../notifications-button';
import { CONTACT_EMAIL, CONTACT_PHONE } from "../../../utils/constants";

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

export const TopNav = (props) => {
    const { onMobileNavOpen, ...other } = props;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <Box
            component="header"
            sx={{
                backdropFilter: 'blur(6px)',
                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
                position: 'sticky',
                left: {
                    lg: `${SIDE_NAV_WIDTH}px`,
                },
                top: 0,
                width: {
                    lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
                },
                zIndex: (theme) => theme.zIndex.appBar,
            }}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="column"
                spacing={smDown ? 1 : 2}
                sx={{
                    minHeight: TOP_NAV_HEIGHT,
                    px: 2,
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        width: '100%',
                    }}
                >
                    <Stack direction="row"
                           alignItems="center"
                           spacing={1}>
                        {!lgUp && (
                            <IconButton onClick={onMobileNavOpen}>
                                <SvgIcon>
                                    <Menu01Icon />
                                </SvgIcon>
                            </IconButton>
                        )}
                        {!smDown && (
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <SvgIcon fontSize="small">
                                        <LocalPhoneIcon />
                                    </SvgIcon>
                                    <Typography variant="body2"
                                                color="text.secondary"
                                                sx={{ ml: 0.5 }}>
                                        {CONTACT_PHONE}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                                    <SvgIcon fontSize="small">
                                        <EmailIcon />
                                    </SvgIcon>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ cursor: 'pointer', textDecoration: 'none', ml: 0.5 }}
                                        component="a"
                                        href={`mailto:${CONTACT_EMAIL}`}
                                    >
                                        {CONTACT_EMAIL}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Stack>
                    <Stack direction="row"
                           alignItems="center"
                           spacing={2}
                           sx={{ ml: 'auto' }}>
                        <NotificationsButton />
                        <AccountButton />
                    </Stack>
                </Stack>
                {smDown && (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        spacing={0.5}
                        sx={{
                            width: '100%',
                            mt: 1,
                            textAlign: 'center'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SvgIcon fontSize="small">
                                <LocalPhoneIcon />
                            </SvgIcon>
                            <Typography variant="body2"
                                        color="text.secondary"
                                        sx={{ ml: 0.5 }}>
                                {CONTACT_PHONE}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SvgIcon fontSize="small">
                                <EmailIcon />
                            </SvgIcon>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ cursor: 'pointer', textDecoration: 'none', ml: 0.5 }}
                                component="a"
                                href={`mailto:${CONTACT_EMAIL}`}
                            >
                                {CONTACT_EMAIL}
                            </Typography>
                        </Box>
                    </Stack>
                )}
            </Stack>
        </Box>
    );
};

TopNav.propTypes = {
    onMobileNavOpen: PropTypes.func,
};
