import { combineReducers } from '@reduxjs/toolkit';

import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as batchItemReducer } from "../slices/batch-item";
import { reducer as inventoryItemReducer } from "../slices/inventory-item";
import { reducer as productItemReducer } from "../slices/product-item";
import { reducer as scanInventoryItemReducer } from "../slices/scan-item";
import { reducer as productAttributesReducer } from "../slices/product-attribute";
import { reducer as companyRolesReducer } from "../slices/company-roles";
import { reducer as ebayPolicyReducer } from "../slices/ebay/ebay-policy-component";
import { reducer as ebayPolicyDashboardReducer } from "../slices/ebay/ebay-policy-dashboard-component";
import { reducer as ebayConditionReducer } from "../slices/ebay/ebay-condition-component";
import { reducer as ebayCategoryReducer } from "../slices/ebay/ebay-category-component";
import { reducer as countryReducer } from "../slices/ebay/ebay-country-component";
import { reducer as mobilePhotoUrlReducer } from "../slices/mobile-url-component";
import { reducer as ebayLocationsReducer } from "../slices/ebay/ebay-location-dashboard-component";
import { reducer as invitationReducer } from "../slices/invitation-component";

export const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  batchItems: batchItemReducer,
  inventoryItems: inventoryItemReducer,
  productItem: productItemReducer,
  scanInventoryItem: scanInventoryItemReducer,
  productAttributes: productAttributesReducer,
  companyRoles: companyRolesReducer,
  ebayPolicy: ebayPolicyReducer,
  ebayPolicyDashboard: ebayPolicyDashboardReducer,
  ebayCondition: ebayConditionReducer,
  ebayCategory: ebayCategoryReducer,
  country: countryReducer,
  mobilePhotoUrl: mobilePhotoUrlReducer,
  ebayLocations: ebayLocationsReducer,
  invitation: invitationReducer,
});
