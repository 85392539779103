// AuthProvider.js
import {useCallback, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import {googleLogout, GoogleOAuthProvider} from '@react-oauth/google';
import {AuthContext, initialState} from './auth-context';
import {api} from '../../../api';
import {apiConfig, GoogleApi} from "../../../config.js";
import {Issuer} from 'src/utils/auth';
import {jwtDecode} from 'jwt-decode';
import {CookieStorage} from '@aws-amplify/core';
import axios from "axios";
import {REFRESH_TOKEN_KEY, ACCESS_TOKEN_KEY} from "../../../store/const";
import {useRouter} from "../../../hooks/use-router.js";
import {paths} from "../../../paths.js";
import toast from "react-hot-toast";
import {calcAllAllowedRoles} from "../utils.js";
import {CONFIRMATION_EMAIL} from "../../../utils/constants.js";

const cookieStorage = new CookieStorage({
  domain: window.location.hostname,
  secure: true,
  sameSite: 'lax',
  path: '/',

});

const baseUrl = apiConfig.apiUrl;

const getUserFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return {
      id: decodedToken.id,
      email: decodedToken.email,
      displayName: decodedToken.displayName,
      photo: decodedToken.photo,
      phoneNumber: decodedToken.phoneNumber,
      createdAt: decodedToken.createdAt,
      hasEbayAccount: decodedToken.hasEbayAccount,
      roles: decodedToken.roles,
      showWelcomePage: decodedToken.showWelcomePage,
    };
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};
var ActionType;
(function (ActionType) {
  ActionType['INITIALIZE'] = 'INITIALIZE';
  ActionType['SIGN_IN'] = 'SIGN_IN';
  ActionType['SIGN_OUT'] = 'SIGN_OUT';
  ActionType['REGISTER'] = 'REGISTER';
  ActionType['TOKEN_REFRESHED'] = 'TOKEN_REFRESHED';
})(ActionType || (ActionType = {}));

const handlers = {
  INITIALIZE: (state, action) => {
    const {isAuthenticated, user, token} = action.payload;
    return {...state, isAuthenticated, isInitialized: true, user, token};
  },
  SIGN_IN: (state, action) => {
    const {user, token} = action.payload;
    return {...state, isAuthenticated: true, user, token};
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    token: null,
  }),
  TOKEN_REFRESHED: (state, action) => {
    const {user, token} = action.payload;
    return {...state, isAuthenticated: true, user, token};
  },
  REGISTER: (state, action) => {
    const {user, token} = action.payload;
    return {...state, isAuthenticated: true, user, token};
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthProvider = (props) => {
  const {children} = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();

  let isRefreshing = false;
  let refreshSubscribers = [];

  const onRefreshed = (token) => {
    refreshSubscribers.map((callback) => callback(token));
  };

  const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
  };

  const initialize = useCallback(async () => {
    try {
      let token = localStorage.getItem(ACCESS_TOKEN_KEY);
      if (token === null || token === undefined) {
        await AuthProvider.refreshToken();
      }

      if (token) {
        const user = getUserFromToken(token);
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            token,
            user,
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            token: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          token: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signInWithGoogleAuth = useCallback(async (googleToken) => {
    try {
      const response = await axios.post(`${baseUrl}/auth-jwt/connect/google/check`, {
        token: googleToken,
      });
      const {token, refreshToken} = response.data;
      const user = getUserFromToken(token);

      localStorage.setItem(ACCESS_TOKEN_KEY, token);
      await cookieStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          isAuthenticated: true,
          user: user,
          token,
        },
      });
      toast.success('Login successful')
    } catch (error) {
      toast.error('Login failed: ' + error);
      throw error;
    }
  }, [dispatch]);

  const signOut = useCallback(() => {
    googleLogout();
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    cookieStorage.removeItem(REFRESH_TOKEN_KEY);

    dispatch({type: ActionType.SIGN_OUT});
  }, [dispatch]);


  const refreshToken = useCallback(async () => {
    const refreshToken = await cookieStorage.getItem(REFRESH_TOKEN_KEY);

    if (refreshToken === null || refreshToken === undefined) {
      dispatch({ type: ActionType.SIGN_OUT });
      return null;
    }

    if (isRefreshing) {
      return new Promise((resolve) => {
        addRefreshSubscriber(resolve);
      });
    }

    isRefreshing = true;

    try {
      const response = await axios.post(`${baseUrl}/auth-jwt/token/refresh`, {
        refresh_token: refreshToken,
      });
      const { token, refreshToken: newRefreshToken } = response.data;
      localStorage.setItem(ACCESS_TOKEN_KEY, token);
      await cookieStorage.removeItem(REFRESH_TOKEN_KEY);
      await cookieStorage.setItem(REFRESH_TOKEN_KEY, newRefreshToken);
      const user = getUserFromToken(token);
      dispatch({
        type: ActionType.TOKEN_REFRESHED,
        payload: { token: token, user: user },
      });
      onRefreshed(token);
      refreshSubscribers = [];
      isRefreshing = false;

      return token;
    } catch (error) {
      isRefreshing = false;
      refreshSubscribers = [];
      toast.error('Token refresh failed: ' + error);
      router.push(paths.notAuthorized);
      signOut();
      return null;
    }
  }, [dispatch, signOut, router, isRefreshing, refreshSubscribers]);

  const registerUser = useCallback(async (data) => {
    try {
      if (data.email && !data.invitation) {
        const response = await axios.post(`${baseUrl}/api/confirmation/send-confirm`, {...data});
        if (response.data.data === CONFIRMATION_EMAIL.SENT) {
          router.push(paths.successSentConfirmationEmail);
        }
        return;
      }
      const response = await axios.post(`${baseUrl}/auth-jwt/register`, {
        ...data
      });
      const {token, refreshToken} = response.data;
      const user = getUserFromToken(token);

      localStorage.setItem(ACCESS_TOKEN_KEY, token);
      await cookieStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          isAuthenticated: true,
          user: user,
          token,
        },
      });

      toast.success('Registration successful');
      return user;
    }  catch (error) {
      if (error.response) {
        const status = error.response.status;
        const message = error.response.data.error || 'An error occurred during registration';

        if (status === 400) {
          toast.error('Bad Request: ' + message);
        } else if (status === 409) {
          toast.error('Conflict: ' + message);
        } else {
          toast.error('Error: ' + message);
        }
      } else if (error.request) {

        toast.error('No response received from server');
      } else {
        toast.error('Error: ' + error.message);
      }

      console.log(error);
      throw error;
    }
  }, [dispatch]);

  const registerUserAfterConfirmation = useCallback(async (data) => {
    try {
      const {token, refreshToken} = data;
      const user = getUserFromToken(token);

      localStorage.setItem(ACCESS_TOKEN_KEY, token);
      await cookieStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          isAuthenticated: true,
          user: user,
          token,
        },
      });

      toast.success('Registration successful');
      return user;
    }  catch (error) {
      if (error.response) {
        const status = error.response.status;
        const message = error.response.data.error || 'An error occurred during registration';

        if (status === 400) {
          toast.error('Bad Request: ' + message);
        } else if (status === 409) {
          toast.error('Conflict: ' + message);
        } else {
          toast.error('Error: ' + message);
        }
      } else if (error.request) {

        toast.error('No response received from server');
      } else {
        toast.error('Error: ' + error.message);
      }

      console.log(error);
      throw error;
    }
  }, [dispatch]);

  const signInWithEmailAndPassword = useCallback(async (data) => {
    const response = await axios.post(`${baseUrl}/auth-jwt/connect/google/check`, {...data});
    try {

      const {token, refreshToken} = response.data;
      const user = getUserFromToken(token);

      localStorage.setItem(ACCESS_TOKEN_KEY, token);
      await cookieStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          isAuthenticated: true,
          user: user,
          token,
        },
      });

      return response;
    } catch (error )  {
      if (response.status === 401) {
        return response.data;
      }
      toast.error(error);
      throw error;
    }
  }, [dispatch]);

  const forgotPassword = useCallback(async (email) => {
    try {
      if (!email) {
        toast.error('Email is required');
        return;
      }
      const response = await axios.post(`${baseUrl}/auth-jwt/forgot-password`, {email});

      if (response.status === 200) {
        toast.success('Password reset link has been sent to your email');
      } else {
        toast.error('Failed to send password reset email');
      }
    } catch (error) {
      console.error('Error during password reset:', error);

      if (error.response) {
        const {status, data} = error.response;

        if (status === 400) {
          toast.error('Invalid email address');
        } else if (status === 404) {
          toast.error('User not found');
        } else {
          toast.error('An error occurred during password reset');
        }
      } else {
        toast.error('An error occurred during password reset');
      }
    }
  }, []);

  const resetPassword = useCallback(async (data) => {
    try {
      const response = await axios.post(`${baseUrl}/auth-jwt/reset-password`, {...data});

      if (response.status === 200) {
        toast.success('Password reset now you can sign in');
      } else {
        toast.error('Failed to reset password');
      }
    } catch (error) {
      console.error('Error during password reset:', error);
        toast.error('An error occurred during password reset');
    }
  }, []);

  AuthProvider.refreshToken = async () => {
    return refreshToken();
  };

  const includeRoles = (roles) => {
    if (!roles.length) {
      return true;
    }
    const authUser = state.user;
    if (authUser === null) {
      return false;
    }
    const userRoles = calcAllAllowedRoles(authUser.roles);
    return roles.map((role) => userRoles.includes(role))
        .reduce((previousValue, currentValue) => previousValue || currentValue, false);
  };

  return (
    <GoogleOAuthProvider clientId={GoogleApi.clientId}>
      <AuthContext.Provider
        value={{
          ...state,
          issuer: Issuer.Google,
          signInWithGoogleAuth,
          signInWithEmailAndPassword,
          signOut,
          refreshToken,
          registerUser,
          registerUserAfterConfirmation,
          forgotPassword,
          resetPassword,
          includeRoles,
        }}
      >
        {children}
      </AuthContext.Provider>
    </GoogleOAuthProvider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
