import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';

import {Layout as DashboardLayout} from 'src/layouts/dashboard';
import {DashboardFooter} from "../layouts/dashboard/dashboard-footer";
import {RoleBasedGuard} from "../guards/role-based-guard.jsx";
import {roles} from "../utils/user-roles.js";

const DataManagerDemoRequestPage = lazy(() => import('src/pages/dashboard/data-management/demo-request'));
const DataManagerEmailDemoRequestPage = lazy(() => import('src/pages/dashboard/data-management/email-demo-request'));

export const adminRoutes = [
    {
        path: 'admin',
        element: (
            <>
                <RoleBasedGuard roles={[roles.ROLE_SUPER_ADMIN]}>
                    <DashboardLayout>
                        <Suspense>
                            <Outlet />
                        </Suspense>
                    </DashboardLayout>
                    <DashboardFooter />
                </RoleBasedGuard>
            </>
        ),
        children: [
            {
                path: 'demo-requests',
                element: <DataManagerDemoRequestPage />
            },
            {
                path: 'email-demo-requests',
                element: <DataManagerEmailDemoRequestPage />
            },
        ],
    },
];
