import { useRoutes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SettingsButton } from 'src/components/settings/settings-button';

import 'src/global.css';
// Remove if locales are not used
import 'src/locales/i18n';

import { RTL } from 'src/components/rtl';
import { SplashScreen } from 'src/components/splash-screen';
import { SettingsDrawer } from 'src/components/settings/settings-drawer';
import { Toaster } from 'src/components/toaster';
import { AuthConsumer, AuthProvider } from 'src/contexts/auth/auth-google';
import { SettingsConsumer, SettingsProvider } from 'src/contexts/settings';
import { useNprogress } from 'src/hooks/use-nprogress';
import { routes } from 'src/routes';
import { store } from 'src/store';
import { createTheme } from 'src/theme';
import CookiePopup from './components/cookie/cookie';
import {useAuth} from "./hooks/use-auth.js";
import {useContext} from "react";
import { useEffect } from "react";
import {marketplaceCategoryCalls} from "./api/marketplace/category/index.js";


export const App = () => {
    useNprogress();

    const element = useRoutes(routes);
    const isTakeInventoryPhotoPage = location.pathname.includes('/take-inventory-photo/');

    return (
        <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AuthProvider>
                    <AuthConsumer>
                        {(auth) => (
                            <SettingsProvider>
                                <SettingsConsumer>
                                    {(settings) => {
                                        // Prevent theme flicker when restoring custom settings from browser storage
                                        if (!settings.isInitialized) {
                                            // return null;
                                        }

                                        const theme = createTheme({
                                            colorPreset: settings.colorPreset,
                                            contrast: settings.contrast,
                                            direction: settings.direction,
                                            paletteMode: settings.paletteMode,
                                            responsiveFontSizes: settings.responsiveFontSizes,
                                        });

                                        // Prevent guards from redirecting
                                        const showSlashScreen = !auth.isInitialized;

                                        return (
                                            <ThemeProvider theme={theme}>
                                              <Helmet>
                                                <meta
                                                  name="color-scheme"
                                                  content={settings.paletteMode}
                                                />
                                                <meta
                                                  name="theme-color"
                                                  content={theme.palette.neutral[900]}
                                                />
                                                <meta name="google-site-verification"
                                                      content="U8dE5FfOYEsapdxyzuIcmZc2xS4X8-K6jJu8ci9sVc4"/>
                                              </Helmet>
                                              <RTL direction={settings.direction}>
                                              <CssBaseline />
                                                    {showSlashScreen ? (
                                                        <SplashScreen />
                                                    ) : (
                                                        <>
                                                            {element}
                                                            {
                                                                !auth.isAuthenticated || !isTakeInventoryPhotoPage && <SettingsButton onClick={settings.handleDrawerOpen} />
                                                            }
                                                            <SettingsDrawer
                                                                canReset={settings.isCustom}
                                                                onClose={settings.handleDrawerClose}
                                                                onReset={settings.handleReset}
                                                                onUpdate={settings.handleUpdate}
                                                                open={settings.openDrawer}
                                                                values={{
                                                                    colorPreset: settings.colorPreset,
                                                                    contrast: settings.contrast,
                                                                    direction: settings.direction,
                                                                    paletteMode: settings.paletteMode,
                                                                    responsiveFontSizes: settings.responsiveFontSizes,
                                                                    stretch: settings.stretch,
                                                                    layout: settings.layout,
                                                                    navColor: settings.navColor,
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                    <Toaster />
                                                    <CookiePopup />
                                                </RTL>
                                            </ThemeProvider>
                                        );
                                    }}
                                </SettingsConsumer>
                            </SettingsProvider>
                        )}
                    </AuthConsumer>
                </AuthProvider>
            </LocalizationProvider>
        </ReduxProvider>
    );
};
